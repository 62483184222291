.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-card {
    display: flex;
    background-color: #333;
    text-align: center;
    color: #fff;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 50vw;
    height: 50vh;
    font-size: 20px;
    border: none;
    box-shadow: 5px 5px 20px rgb(0, 0, 0, 0.5);
}
.btn-close {
    padding: 10px;
    background-color: #fff;
    border-radius: 50%;
    margin-top: 5px;
    margin-right: 5px;
    position: absolute;
    top: 0;
    right: 0;
}
.button .btn {
    color: #333;
    background: #fff !important;
}
@media (max-width: 440px) {
    .edit-card {
        width: auto;
        padding: 10px;
    }
} ;
